import { PENCILSIZE, SERVER_URL } from "../Constants"
import ActionBar from "./ActionBar"
import TopActionBar from "./TopActionBar"
import PromptBox from "./PromptBox"
import "./SketchBook.css"
import SketchPad from "./SketchPad"
import Toolbar from "./Toolbar"
import { useEffect, useRef, useState } from "react"
import { imgStrToBase64, sendToEndpoint } from "../ExportUtils"
import { getMode, validatePrompt } from "../Utils"
import { importImageFromFile } from "../CanvasUtils"
import FileActions from "./FileActions"
import PrintBook from "./PrintBook"

export default function SketchBook () {
    const toolbarRef = useRef()
    const promptboxRef = useRef()
    const scrollCanvasRef = useRef()
    const canvasRef = useRef()
    const actionBarRef = useRef()
    const topActionBarRef = useRef()
    const intervalRef = useRef()

    const [color, setColor] = useState("#007AFF")
    const [toolType, setToolType] = useState("pencil")
    const [brushsize, setBrushsize] = useState(PENCILSIZE)
    const [hotkeyPressed, setHotkeyPressed] = useState(null)
    const [showActionBar, setShowActionBar] = useState(false)
    const [isGenerate, setIsGenerate] = useState(false)
    const [prompt, setPrompt] = useState("")
    const [artStyle, setArtStyle] = useState("concept")
    const [printMode, setPrintMode] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)
    const [jobID, setJobID] = useState(null)

    useEffect(() => {
        if (jobID) {
            clearInterval(intervalRef.current)
            intervalRef.current = setInterval(() => {
                retrieveImage(jobID)
            }, 3000)
        }
    }, [jobID])

    const disablePointerEvents = bool => {
        [toolbarRef, promptboxRef, scrollCanvasRef, actionBarRef, topActionBarRef].map(ref => 
            ref.current?.classList[bool ? "add" : "remove"]("no-events")
        )
    }

    const hotkeyAction = (hotkey) => {
        if (hotkey) performAction(hotkey.action)
        setHotkeyPressed(hotkey)
    }

    const performAction = (action) => {
        if (action === "undo") {
            canvasRef.current.undo()
        }
        if (action === "redo") {
            canvasRef.current.redo()
        }
        if (action === "zoom") {
            canvasRef.current.zoom(null, 1)
        }
        if (action === "zoomIn") {
            canvasRef.current.zoom(2.5, null)
        }
        if (action === "zoomOut") {
            canvasRef.current.zoom(-2.5, null)
        }
    }

    const generateImage = async () => {
        
        setIsGenerate(true)

        const canvasData = canvasRef.current.exportCanvas()

        if (canvasData === null || prompt === "") {
            setErrorMsg(!canvasData ? "There are no drawings on the page to generate a picture" : "There is no prompt written to instruct the A.I. on what to draw")
            setIsGenerate(false)
            return
        }

        const argBody = Object.assign({
            type: artStyle,
            mode: getMode(canvasData),
            prompt: prompt,
            ...canvasData
        })

        console.log(argBody)

        const result = await sendToEndpoint(SERVER_URL + "/sendto", argBody)

        if (result.type === "success") {
            setJobID(result.payload.id)
        } else {
            console.log("error")
            setIsGenerate(false)
        }
        
/*
        var newTab = window.open('about:blank','image from canvas');
        newTab.document.write("<img src='" + imgStrToBase64(canvasData.images) + "' alt='from canvas'/>");
*/
    }

    const retrieveImage = async (id) => {
        
        const result = await sendToEndpoint(SERVER_URL + "/getfrom", { id })

        console.log(result.payload.status)

        if (result.type === "success") {
            
            if (result.payload.status != "IN_QUEUE" && result.payload.status != "IN_PROGRESS") {
                clearInterval(intervalRef.current)
                setIsGenerate(false)
                setJobID(null)
            }

            if (result.payload.status === "COMPLETED") {
                const parsedResult = JSON.parse(result.payload.output)
                canvasRef.current.addImage(imgStrToBase64(parsedResult.images[0]))
            } 

        } else {
            console.log("error")
            clearInterval(intervalRef.current)
            setIsGenerate(false)
            setJobID(null)
        }
    }

    const downloadImage = () => {
        canvasRef.current.downloadImage()
    }

    const printImage = () => {
        canvasRef.current.printImage(setPrintMode)
    }

    const newPage = () => {
        setPrompt("")
        canvasRef.current.clearPage()
    }

    const importImage = () => {
        if (canvasRef.current.getImage()) {
            const result = window.confirm("Clear the current image?")
            if (!result) return
        }
        
        importImageFromFile((src, dimensions) => {
            canvasRef.current.addImage(src, dimensions);
        })
    }

    return (
        <div className="sketchbook">
            <SketchPad
                disablePointerEvents={disablePointerEvents}
                scrollCanvasRef={scrollCanvasRef}
                color={color}
                brushsize={brushsize}
                toolType={toolType}
                canvasRef={canvasRef}
                hotkeyAction={hotkeyAction}
                setShowActionBar={setShowActionBar}
                isGenerate={isGenerate}
                errorMsg={errorMsg}
                setErrorMsg={setErrorMsg}
            />
            <ActionBar
                hotkeyPressed={hotkeyPressed}
                setHotkeyPressed={setHotkeyPressed}
                performAction={performAction}
                actionBarRef={actionBarRef}
                showActionBar={showActionBar}
                isGenerate={isGenerate}
            />
            <TopActionBar
                performAction={performAction}
                actionBarRef={topActionBarRef}
            />
            <div className="right-panel">
                <Toolbar 
                    toolbarRef={toolbarRef}
                    color={color}
                    setColor={setColor}
                    toolType={toolType}
                    setToolType={setToolType}
                    brushsize={brushsize}
                    setBrushsize={setBrushsize}
                    hotkeyPressed={hotkeyPressed}
                    setHotkeyPressed={setHotkeyPressed}
                />
                <FileActions 
                    hotkeyPressed={hotkeyPressed}
                    setHotkeyPressed={setHotkeyPressed}
                    downloadImage={downloadImage}
                    printImage={printImage}
                    newPage={newPage}
                    importImage={importImage}
                />
            </div>
            <PromptBox 
                promptboxRef={promptboxRef}
                isGenerate={isGenerate}
                generateImage={generateImage}
                prompt={prompt}
                setPrompt={setPrompt}
                artStyle={artStyle}
                setArtStyle={setArtStyle}
                setErrorMsg={setErrorMsg}
            />
            {printMode && <PrintBook 
                setPrintMode={setPrintMode}
                image={canvasRef.current.getImage()}
            />}
        </div>
    )
}