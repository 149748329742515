import "./TopActionBar.css"
import { TOP_ACTIONS } from "../Constants"

function ActionButton({ button, performAction }) {
    return (
        <button className="action-btn">
            <div 
                className="icon"
                style={{ backgroundImage: `url(./img/${button.icon})` }}
                onClick={() => performAction(button.action)}
            />
        </button>
    )
}

export default function TopActionBar({ performAction, actionBarRef }) {
    return (
        <div 
            className={`top-action-bar show`}
            ref={actionBarRef}
        >
            {TOP_ACTIONS.map(action => (
                <ActionButton
                    key={action.icon}
                    button={action}
                    performAction={performAction}
                />
            ))}
        </div>
    )
} 