import { FILE_ACTIONS } from "../Constants"
import "./Toolbar.css"
import ToolItem from "./ToolItem"

export default function FileActions ({hotkeyPressed, setHotkeyPressed, downloadImage, printImage, newPage, importImage}) {

    const fileAction = (action) => {
        if (action === "save") 
            downloadImage()
        
        if (action === "print")
            printImage()
        
        if (action === "new")
            newPage()

        if (action === "import")
            importImage()
    }

    return (
        <div className="file-action-bar">

            {FILE_ACTIONS.map(t => (<ToolItem 
                key={t.type}
                icon={t.icon}
                type={t.type}
                hotkey={t.hotkey}
                hotkeyPressed={hotkeyPressed}
                setHotkeyPressed={setHotkeyPressed}
                toolAction={fileAction}
            />))}
        </div>
    )
}