import { useState, useEffect } from "react";
import "./PrintBook.css"

export default function PrintBook ({ image, setPrintMode }) {
    const [imgIsPortrait, setImgIsPortrait] = useState(null)

    useEffect(() => {

        if (image && imgIsPortrait === null) {
            const { w, h } = image.bounds
            setImgIsPortrait(h > w)
        }

    }, [image])

    const handlePrintDone = () => {
        setPrintMode(false)
    }

    useEffect(() => {
        if (imgIsPortrait !== null) {
            window.print()
        }
    }, [imgIsPortrait])

    return (
        <div className="print-wrapper">
            <div className="no-print">
                <button onClick={handlePrintDone}>Done Printing</button>
            </div>
            <style type="text/css">
                {`@media print{
                    @page {
                        size: ${imgIsPortrait ? "portrait" : "landscape"};
                        margin: 0;
                    }
                }`}
            </style>
            <img src={image.image.src} 
            />
        </div>
    )  
}