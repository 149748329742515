import { useEffect, useState, forwardRef, useImperativeHandle } from "react"
import "./CanvasInstructions.css"
import { CANVAS_INSTRUCTIONS } from "../Constants"
import { isTouchScreen } from "../Utils"

const CanvasInstructions = forwardRef( ({errorMsg}, ref) => {
    const [instructs, setInstructs] = useState(CANVAS_INSTRUCTIONS)

    useImperativeHandle(ref, () => ({
        didDo(action) {
            setInstructs(prev => prev.filter(intr => intr.type !== action))
        }
    }))

    const getMsg = () => {
        if (errorMsg) {
            return errorMsg
        } else if (instructs.length > 0) {
            return isTouchScreen() && instructs[0].touch ? instructs[0].touch : instructs[0].text
        }
    }

    return (
        <div className={`instructions ${errorMsg && "error"}`} key={getMsg()}>{
            getMsg()
        }</div>
    )
})

export default CanvasInstructions