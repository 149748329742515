export const SERVER_URL = "https://aisketch.onrender.com"
//export const SERVER_URL = "http://localhost:4000"


export const DEFAULT_PEN_PRESSURE = 0.5
export const BRUSHSIZES = [12, 16, 24]
export const PENCILSIZE = 4
export const DEFAULT_BRUSHSIZES = {
    pencil: PENCILSIZE,
    brush: BRUSHSIZES[1],
    erase: BRUSHSIZES[0]
}

export const BLUR_EDGE = 8

export const TOOL_HOTKEYS = [{
    label: "B",
    key: "b",
    held: "",
    action: "brush"
}, {
    label: "P",
    key: "p",
    held: "",
    action: "pencil"
}, {
    label: "E",
    key: "e",
    held: "",
    action: "erase"
}, {
    label: "SHFT S",
    key: "S",
    held: "Shift",
    action: "save"
}, {
    label: "SHFT P",
    key: "P",
    held: "Shift",
    action: "print"
}, {
    label: "SHFT I",
    key: "I",
    held: "Shift",
    action: "import"
}, {
    label: "SHFT N",
    key: "N",
    held: "Shift",
    action: "new"
}]

export const ACTION_HOTKEYS = [{
    label: "CTRL Z",
    key: "z",
    held: "Control",
    action: "undo"
}, {
    label: "CTRL SHFT Z",
    key: "Z",
    held: "Control+Shift",
    action: "redo"
}, {
    label: "",
    key: "+",
    held: "Shift",
    action: "zoomIn"
}, {
    label: "",
    key: "_",
    held: "Shift",
    action: "zoomOut"
}, {
    label: "",
    key: ")",
    held: "Shift",
    action: "zoom"
}]

export const CANVAS_ACTIONS = [{
    icon: "icon-undo.svg",
    hotkey: ACTION_HOTKEYS[0]
}, {
    icon: "icon-redo.svg",
    hotkey: ACTION_HOTKEYS[1]
}]

export const TOP_ACTIONS = [{
    icon: "logo-aisketch.svg",
    type: "logo",
    action: "reload"
}]

export const TOOLTYPES = [{
    type: "pencil",
    icon: "icon-pencil.svg",
    mode: "scribbles",
    hotkey: TOOL_HOTKEYS[1]
}, {
    type: "brush",
    icon: "icon-brush.svg",
    mode: "segments",
    hotkey: TOOL_HOTKEYS[0]
}, {
    type: "erase",
    icon: "icon-eraser.svg",
    mode: "erase",
    hotkey: TOOL_HOTKEYS[2]
}]

export const TOOLMODE = {
    pencil: "scribbles",
    brush: "segments",
    erase: "erase"
}

export const TOOLMAP_ARRAY = {
    pencil: ["scribbles"],
    brush: ["segments"],
    erase: ["segments", "scribbles", "inpaints", "images"]
}

export const HOTKEY_ANIMATE_DEFAULT = {
    shouldAnimate: false,
    aniKey: 0
}

export const CANVAS_INSTRUCTIONS = [{
    type: "move canvas",
    text: "Hold SPACEBAR and Drag Mouse to Move Canvas",
    touch: "Drag with Two Fingers to Move Canvas"
}, {
    type: "zoom canvas",
    text: "Hold SHIFT and Press = or - to Adjust Zoom",
    touch: "Pinch with Two Fingers to Adjust Zoom"
}]

export const ART_STYLES = [{
    value: "concept",
    label: "Concept art"
}, {
    value: "sketch",
    label: "Black & white sketch"
}]


export const FILE_ACTIONS = [{
    icon: "icon-add-page.svg",
    type: "new",
    hotkey: TOOL_HOTKEYS[6]
},{
    icon: "icon-import.svg",
    type: "import",
    hotkey: TOOL_HOTKEYS[5]
}, {
    icon: "icon-save.svg",
    type: "save",
    hotkey: TOOL_HOTKEYS[3]
}, {
    icon: "icon-print.svg",
    type: "print",
    hotkey: TOOL_HOTKEYS[4]
}]
