import { canvasMinScaleFactor } from "./ExportUtils"
import { imageLoad } from "./Utils"

export const scaleCanvas = (canvas, width, height, scale) => {
    canvas.width = width * scale
    canvas.height = height * scale
    canvas.style.width = `${width}px`
    canvas.style.height = `${height}px`
    const context = canvas.getContext("2d")
    context.scale(scale, scale)
    return context
}

export const clearCanvas = canvas => {
    const context = canvas.getContext("2d")
    context.save()
    context.setTransform(1, 0, 0, 1, 0, 0)
    context.clearRect(0, 0, canvas.width, canvas.height)
    context.restore()
    return context
}

export const drawCanvasBounds = (canvas, bounds) => {
    const {x, y, w, h} = bounds
    const context = clearCanvas(canvas) 
    context.strokeStyle = "#CDCDCD"
    context.strokeRect(x, y, w, h)
}

export const drawScrollBar = (elm, orient, offset, overflow, viewSize) => {
    elm.style[orient === "vertical" ? "height" : "width"] = `${viewSize / (viewSize + overflow) * 100}%`
    elm.style[orient === "vertical" ? "top" : "left"] = `${offset / (viewSize + overflow) * 100}%`
    elm.style.opacity = overflow > 0 ? 1 : 0
}

export const drawScrollState = (vertScrollBar, horScrollBar, canvas, bounds) => {
    const {
        overflowLeft,
        overflowX,
        viewportWidth,
        overflowTop,
        overflowY,
        viewportHeight,
    } = getScrollState(canvas, bounds)

    drawScrollBar(vertScrollBar, "vertical", overflowTop, overflowY, viewportHeight)
    drawScrollBar(horScrollBar, "horizontal", overflowLeft, overflowX, viewportWidth)
}

export const getScrollState = (canvas, bounds) => {
    const context = canvas.getContext("2d")
    const canvasOrigin = context.transformedPoint(0, 0)
    const canvasSize = context.transformedPoint(canvas.width, canvas.height)

    const overflowLeft = Math.max(canvasOrigin.x - bounds.x, 0)
    const overflowRight = Math.max((bounds.x + bounds.w) - canvasSize.x, 0)
    const overflowX = overflowLeft + overflowRight
    const overflowTop = Math.max(canvasOrigin.y - bounds.y, 0)
    const overflowBottom = Math.max((bounds.y + bounds.h) - canvasSize.y, 0)
    const overflowY = overflowTop + overflowBottom

    return {
        overflowLeft,
        overflowRight,
        overflowX,
        viewportWidth: canvas.width,
        overflowTop,
        overflowBottom,
        overflowY,
        viewportHeight: canvas.height
    }
}

export const drawSketches = (context, sketches) => {
    sketches.forEach((sketch) => {

        if (sketch.image) {
            drawImage(context, sketch.image, sketch.bounds)
        } else {
            drawStroke(context, sketch)
        }
    })
}
  
const getStrokeStyle = (context, currStroke, forExport) => {

    context.fillStyle = currStroke.color
    context.globalCompositeOperation = "source-over"

    if (currStroke.type === "erase" && !forExport) {
        context.globalCompositeOperation = "destination-out"
    }

    if (forExport && currStroke.mode !== "segments") {
        context.fillStyle = currStroke.type === "erase" && forExport === 0 ? "black" : "white"
    }

    context.strokeStyle = context.fillStyle
    context.lineWidth = currStroke.thickness
    context.lineCap = "round"
    context.lineJoin = "round"
}
  
export const drawStroke = (context, currStroke, forExport = false) => {
    if (currStroke === null || currStroke.points.length === 0) return

    getStrokeStyle(context, currStroke, forExport)
  
    context.beginPath()
    context.moveTo(currStroke.points[0].x, currStroke.points[0].y);
    
    const points = [...currStroke.points].filter((p, i) => {
        return (i % 2 === 0) || i === currStroke.points.length - 1 
    })

    for (let i = 1; i < points.length - 2; i++) {
        const xc = (points[i].x + points[i + 1].x) / 2;
        const yc = (points[i].y + points[i + 1].y) / 2;
        context.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
    }

    context.lineTo(points[points.length - 1].x, points[points.length - 1].y)
    context.stroke()
    
}

export const drawImage = (context, img, bounds) => {
    const { x, y, w, h, scaleFactor} = bounds
    context.globalCompositeOperation = "source-over"
    context.drawImage(img, x, y, w, h)
}

export const loadNewImage = async (imgData, bounds) => {
    if (imgData) {
        const { w, h, x, y, scaleFactor } = bounds
        const image = await imageLoad(imgData)
        
        const newImage = {
            image,
            bounds
        }
        
        return newImage
    } else {
        return null
    }
}

export const isCanvasBlank = (context) => {
    const { clientWidth, clientHeight } = context.canvas;
    return !context.getImageData(0, 0, clientWidth, clientHeight).data.some(channel => channel !== 0);
}

export const getEventPosition = (e) => {
    
    if (e.touches && e.touches.length > 1)
    {
        let x = e.touches[0].clientX
        let y = e.touches[0].clientY

        if (e.touches.length === 2) {
            x += (e.touches[1].clientX - x) / 2
            y += (e.touches[1].clientY - y) / 2
        }
        return { x, y }
    }
    else if (e.clientX && e.clientY)
    {
        return { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY}        
    }
}

export const shouldEatInput = (activeElm) => {
    return !(activeElm.tagName === "BODY" || ["canvas-base", "tool-item"].some(s => activeElm.classList.contains(s))) 
}

export const getStrokeRadius = (brushsize, pressure, type) => {
    const radius = brushsize * Math.pow(pressure * 2.5, 2)
    return Math.max(radius, brushsize)
}

export const getBrushCursor = (brushsize) => {
    const radius = Math.max(brushsize / 2, 1)
    const size = brushsize + 4
    const center = size / 2
    return `url('data:image/svg+xml;utf8,<svg id="svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="${size}" height="${size}"><circle cx="${center}" cy="${center}" r="${radius + 1}" stroke-width="1" style="stroke: white; fill: transparent;" vector-effect="non-scaling-stroke"/><circle cx="${center}" cy="${center}" r="${radius}" stroke-width="1" style="stroke: black; fill: transparent;" vector-effect="non-scaling-stroke"/></svg>') ${center} ${center}, crosshair`
}

export const getCanvasBounds = (newX, newY, canvasBounds, strokeRadius) => {
    const {x, y, w, h} = canvasBounds
    const x1 = newX + strokeRadius * Math.sign(newX - x)
    const y1 = newY + strokeRadius * Math.sign(newY - y)
    const xDiff = x - x1
    const yDiff = y - y1
    
    return {
        x: Math.min(x, x1),
        y: Math.min(y, y1),
        w: Math.max(x1 < x ? xDiff + w : w, Math.abs(xDiff)),
        h: Math.max(y1 < y ? yDiff + h : h, Math.abs(yDiff))
    }
}

const adjustBounds = (bounds) => {
    const {x, y, w, h} = bounds
    const scaleFactor = canvasMinScaleFactor(w, h)

    const w1 = divisibleByEight(w * scaleFactor) / scaleFactor
    const h1 = divisibleByEight(h * scaleFactor) / scaleFactor
    const x1 = x - Math.abs((w - w1) / 2)
    const y1 = y - Math.abs((h - h1) / 2)
    
    return {
        w: w1,
        h: h1,
        x: x1,
        y: y1,
        scaleFactor
    }
}

export const getCompositeBounds = (bounds1, bounds2) => {
    const x = Math.min(bounds1.x, bounds2.x)
    const y = Math.min(bounds1.y, bounds2.y)
    const w = Math.max(bounds1.x + bounds1.w, bounds2.x + bounds2.w) - x
    const h = Math.max(bounds1.y + bounds1.h, bounds2.y + bounds2.h) - y

    return adjustBounds({x, y, w, h})
}

export const trimCanvasData = (ctx, bounds) => {
    var canvas = ctx.canvas, 
      w = canvas.width, h = canvas.height,
      pix = {x:[], y:[]},
      x, y, index;

    if (w === 0 || h === 0) return null

    const imageData = ctx.getImageData(0, 0, w, h)
    
    for (y = 0; y < h; y++) {
      for (x = 0; x < w; x++) {
        index = (y * w + x) * 4;
        if (imageData.data[index+3] > 0) {
          pix.x.push(x);
          pix.y.push(y);
        } 
      }
    }

    if (pix.x.length === 0 || pix.y.length === 0) 
        return null

    pix.x.sort(function(a,b){return a-b});
    pix.y.sort(function(a,b){return a-b});
    var n = pix.x.length-1;
    
    w = 1 + pix.x[n] - pix.x[0];
    h = 1 + pix.y[n] - pix.y[0];

    return {
        x: pix.x[0] + bounds.x, 
        y: pix.y[0] + bounds.y, 
        w,
        h
    }   
}

export const divisibleByEight = val => (Math.ceil(val / 64) * 64)

export const trimCanvas = (mode, values, bounds) => {

    if (mode === "images")
        return values[0].bounds

    const canvas = document.createElement("canvas")
    canvas.width = bounds.w
    canvas.height = bounds.h
    const context = canvas.getContext("2d")
    context.translate(-bounds.x, -bounds.y)

    values.forEach((stroke) => {
        drawStroke(context, stroke)
    })
     
    const newBounds = trimCanvasData(context, bounds)
    canvas.remove()

    return newBounds === null ? newBounds : adjustBounds(newBounds)
}

export const getCanvasPos = (e, scale) => {
    const pos = getEventPosition(e)
    return {
        x: (pos?.x || 0) * scale,
        y: (pos?.y || 0) * scale,
    }
}

export const getSetCursor = (elm, cursor = false) => {
    if (cursor) {
        elm.style.cursor = cursor
    } else {
        return elm.style.cursor
    }
}

export const shouldAddToQueue = (queue, curr) => {
    const keys = Object.keys(curr)
    let changes
    if (queue.length === 0) {
        changes = keys.filter(key => curr[key].length > 0)
    } else {
        const prev = queue[queue.length - 1]
        changes = keys.filter(key => prev[key].length !== curr[key].length)
    }
    return changes.length > 0
}

export const heldKeys = (e, heldKeys) => {
    if (heldKeys === "Control") {
        return (e.metaKey || e.ctrlKey)
    } else if (heldKeys === "Shift") {
        return e.shiftKey
    } else if (heldKeys === "Control+Shift") {
        return (e.metaKey || e.ctrlKey) + e.shiftKey
    } else {
        return true
    }
}

export const isHotkeyPressed = (hotkeyPressed, hotkey) => JSON.stringify(hotkeyPressed) === JSON.stringify(hotkey)

export const trackTransforms = (ctx) => {
    var svg = document.createElementNS("http://www.w3.org/2000/svg",'svg');
    var xform = svg.createSVGMatrix();
    ctx.getTransform = function(){ return xform; };
    
    var savedTransforms = [];
    var save = ctx.save;
    ctx.save = function(){
        savedTransforms.push(xform.translate(0,0));
        return save.call(ctx);
    };
    var restore = ctx.restore;
    ctx.restore = function(){
        xform = savedTransforms.pop();
        return restore.call(ctx);
    };

    var scale = ctx.scale;
    ctx.scale = function(sx,sy){
        xform = xform.scaleNonUniform(sx,sy);
        return scale.call(ctx,sx,sy);
    };
    var rotate = ctx.rotate;
    ctx.rotate = function(radians){
        xform = xform.rotate(radians*180/Math.PI);
        return rotate.call(ctx,radians);
    };
    var translate = ctx.translate;
    ctx.translate = function(dx,dy){
        xform = xform.translate(dx,dy);
        return translate.call(ctx,dx,dy);
    };
    var transform = ctx.transform;
    ctx.transform = function(a,b,c,d,e,f){
        var m2 = svg.createSVGMatrix();
        m2.a=a; m2.b=b; m2.c=c; m2.d=d; m2.e=e; m2.f=f;
        xform = xform.multiply(m2);
        return transform.call(ctx,a,b,c,d,e,f);
    };
    var setTransform = ctx.setTransform;
    ctx.setTransform = function(a,b,c,d,e,f){
        xform.a = a;
        xform.b = b;
        xform.c = c;
        xform.d = d;
        xform.e = e;
        xform.f = f;
        return setTransform.call(ctx,a,b,c,d,e,f);
    };
    var pt  = svg.createSVGPoint();
    ctx.transformedPoint = function(x,y){
        pt.x=x; pt.y=y;
        return pt.matrixTransform(xform.inverse());
    }

    ctx.invertedPoint = function (x,y) {
        pt.x=x; pt.y=y;
        return pt.matrixTransform(xform);
    }
}

export const getTouchDirection = (touch, prevTouch) => {
    return {
        x: Math.sign(touch.x - prevTouch.x),
        y: Math.sign(touch.y - prevTouch.y)
    }
}

export const importImageFromFile = (onImageLoad) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    
    input.onchange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    onImageLoad(img.src, { width: img.width, height: img.height });
                };
                img.src = event.target.result;
            };
            reader.readAsDataURL(file);
        }
    };
    
    input.click();
}